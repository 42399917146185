<template>
  <div class="sub-page-contents">
    <section :id="'sect' + i" v-for="(sect, i) in sections" :key="i">
      {{ i }}
      <p>{{ sect.p }}</p>
      <div v-if="sect.ipad" class="ipad">
        <div class="cam-wrap">
          <div class="cam"></div>
          <div class="sensor"></div>
        </div>
        <iframe
          class="screen"
          src="https://in3d-website.onrender.com/"
          frameborder="0"
        ></iframe>
        <div class="btn"></div>
      </div>
      <img
        v-else
        :src="`img/sub-pages/${pageName}/${i}.jpg`"
        alt="pageName missing"
      />
    </section>
  </div>
</template>

<script>
export default {
  name: "charts",
  props: ["sections", "pageName"],
};
</script>

<style scoped lang="scss">
section {
  height: 100vh;
  padding: 50px;
}
p {
  width: 40%;
  text-align: left;
  margin-bottom: 3%;
}
img {
  width: 50%;
}
.ipad {
  width: 620px;
  height: 400px;
  background: #ffffff;
  border: 6px solid #a1a1a1;
  border-radius: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .cam-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .cam {
      height: 18px;
      width: 18px;
      background: black;
      border-radius: 50%;
      margin-bottom: 5px;
    }
    .sensor {
      height: 5px;
      width: 5px;
      border: 1px solid gray;
      border-radius: 50%;
    }
  }
  .screen {
    height: 90%;
    width: 84%;
    background: black;
    border: 0.1px solid black;

    border-radius: 4px;
  }
  .btn {
    height: 35px;
    width: 35px;
    border: 2px solid gray;
    border-radius: 50%;
  }
}
</style>