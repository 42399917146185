<template>
  <div class="charts">
    <div class="horizontal-bars">
      <span class="bar-wrap" v-for="(bar, i) in horiBars" :key="i">
        <div class="bar">
          <div
            :class="`inner`"
            :style="{ width: `${anims ? bar : 0}` + '%' }"
          ></div>
        </div>
        <span class="percent">{{ bar }}%</span>
      </span>
    </div>
    <div class="circles-effect">
      <div
        v-for="n in 4"
        :key="n"
        :class="`arc arc${n} ${anims ? 'loaded' : ''}`"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "charts",
  components: {},
  props: [],
  data: () => {
    return { anims: false, horiBars: [100, 70, 50, 20, 10] };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.anims = true;
      }, 1000);
    });
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.charts {
  position: absolute;
  top: 12%;
  right: 0;
  width: 15%;
  height: 50%;
  border-radius: 0 0 0 30px;
  padding: 10px;
  padding-right: 40px;
}
.horizontal-bars {
  width: 100%;
  .bar-wrap {
    display: flex;
    align-items: center;
    height: 10px;
    margin-bottom: 12px;
    transition: transform 0.4s;
    &:hover {
      transform: scale(1.1);
      .bar {
        .inner {
          background: white;
        }
      }
    }
    .bar {
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.199);
      .inner {
        width: 0;
        height: 100%;
        background: rgba(255, 255, 255, 0.555);
        transition: width 3s;
        &.loaded {
          width: 50%;
        }
      }
    }
    .percent {
      text-align: right;
      width: 19%;
    }
  }
}

.circles-effect {
  position: relative;
  margin: 20px;
}

.arc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 100%;

  border-style: solid;
  border-width: 4px;
  border-color: white white white transparent;

  transition-duration: 0.7s;
  transition-timing-function: ease-in-out;

  transform: scale(0) rotate(105deg);

  &:hover {
    border-width: 5px;
    border-color: wheat wheat wheat transparent;
  }
}

.arc1.loaded {
  z-index: 1;
  transform: scale(1) rotate(45deg);
  &:hover {
    transform: scale(1.05) rotate(48deg);
  }
}
.arc2.loaded {
  z-index: 2;
  transform: scale(0.8) rotate(105deg);
  &:hover {
    transform: scale(0.85) rotate(107deg);
  }
}
.arc3.loaded {
  z-index: 3;
  transform: scale(0.6) rotate(5deg);
  &:hover {
    transform: scale(0.65) rotate(12deg);
  }
}
.arc4.loaded {
  z-index: 4;
  transform: scale(0.4) rotate(195deg);
  &:hover {
    transform: scale(0.45) rotate(205deg);
  }
}
</style>