<template>
  <div class="page-hero" @click="scroll">
    <div class="hero" :style="`background-image: url('${heroSrc}')`">
      <div class="headers-wrap">
        <h1 class="title" v-if="title">{{ title }}</h1>
        <h2 v-if="sub">{{ sub }}</h2>
      </div>
    </div>

    <div class="scroll" >
      <div class="inner-wrap">
        <p>SCROLL</p>
        <div class="bar"></div>
        <!-- <span class="arrow"></span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHero",
  props: ["heroSrc", "title", "sub"],
  data: () => {
    return {};
  },
  methods: {
    scroll: function () {
      window.scrollTo({
        top: window.innerHeight,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.hero {
  height: 100vh;
  // width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.headers-wrap {
  position: absolute;
  bottom: 0;
  // transform: translateY(50%);
  text-shadow: 2px 2px black;
  text-align: left;
  padding: 5vw;
  // width: 100vw;
}
.title {
  margin: 0;
  font-size: 4rem;
  max-width: 70%;
}
h2 {
  margin: 0;
}
.scroll {
  position: absolute;
  bottom: 0;
  right: 5%;
  padding: 15px;

  border-radius: 10px;
  &:hover {
    background: rgba(255, 255, 255, 0.212);
  }
  transform: rotate(90deg) translateX(-20%);
  .inner-wrap {
    display: flex;
    align-items: center;
    font-weight: 700;
    .bar {
      animation: squish 2.9s cubic-bezier(1, 0, 0, 1) infinite;
      width: 90px;
      height: 4px;
      background: white;
      margin-left: 15px;
      border-radius: 10px;
    }
    .arrow {
      width: 10px;
      height: 10px;
      border-top: 4px solid white;
      border-right: 4px solid white;
      border-radius: 3px;
      margin-left: -11px;
      transform: rotate(45deg);
    }
  }
}

@keyframes squish {
  0% {
    transform-origin: 0% 0%;
    transform: scale(0, 1);
  }
  50% {
    transform-origin: 0% 0%;
    transform: scale(1, 1);
  }
  50.1% {
    transform-origin: 100% 0%;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: 100% 0%;
    transform: scale(0, 1);
  }
}
</style>